import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Container from './pages/Container';
import TodoList from './pages/TodoList';
import Counter from './pages/Counter';

function App() {

  const [home, setHome] = useState('MyHome');
  
  const handleChangeName = (name) => {
    setHome(name);
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/counter">Counter</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/container">Container</Link></li>
            <li><Link to="/todo">Todo</Link></li>
          </ul>
        </nav>
        <main>
          <Routes>
            <Route path="/" element={<Home name={home} onChangeName={handleChangeName} />} />
            <Route path="/counter" element={<Counter />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/container" element={<Container />} />
            <Route path="/todo" element={<TodoList />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
