import React, { useState } from "react";

const Children = () => {
    console.log("ChildComponent is rendering!");
    return (
        <div>
            Hello Children..!!
        </div>
    )
}


const Parents = ({children}) => {
    console.log("ParentComponent is rendering!");
    const [toggle, setToggle] = useState(false);
    return (
        <div>
            {children}
            <children />
            <button type="" onClick={() => setToggle(!toggle)}>Click Me</button>
            re-render
        </div>
    )
}

const Container = () => {
    return (
    <div>
        <Parents/>
    </div>
    )
}


export default Container