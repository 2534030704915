import React from 'react';

// const Home = (props) => {
//     return (
//         <div>
//             Home {props.name}
//             <br/>
//             <button type="" onClick={()=>props.onChangeName('philip')}>Click Me</button>
//         </div>
//     )
// }

const Home = ({name, onChangeName}) => {
    return (
        <div>
            Home {name}
            <br/>
            <button type="" onClick={()=>onChangeName('philip')}>Click Me</button>
        </div>
    )
}


export default Home;
