import { useState } from "react"

function TodoList() {

    const [todos, setTodos] = useState([]);
    const [inputValue, setInputValue] = useState('');


    const handleChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setTodos([...todos, inputValue]);
        setInputValue('');
    }

    const handleDelete = (index) => {
        const newtodos = [...todos];
        newtodos.splice(index, 1);
        setTodos(newtodos);
    }

    return (
        <div>
        <form>
          <input type="text" value={inputValue} onChange={handleChange} />
          <button onClick={handleSubmit}>Add Todo</button>
          <br />
          <label for="">{inputValue}</label>
        </form>
        <ul>
            {todos.map((todo, index) => (
                <li key={index}>{todo}<button onClick={()=>handleDelete(index)}>Delete</button></li>
            ))}
        </ul>
      </div>        
    )
}

export default TodoList