import React from "react";

function Header () {
    return (
        <header>
            <h1>My App Header</h1>
        </header>
    )
}

export default Header