import React from "react";

function Footer (){
    return (
        <footer>
            <p>© 2024 My First React App. All rights reserved.</p>
        </footer>
    )
}

export default Footer